import React from 'react';
import Layout from '@lekoarts/gatsby-theme-minimal-blog/src/components/layout';
import cv from './resume.json';

const COLUMN_WIDTH = '50%';
const OFFSET = 40;
const MIN_WIDTH = 375;

const Section = ({ name, children }: {name: string, children: any}) => (
  <>
    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '50px' }}>
      <h2>{name}</h2>
      {children}
    </div>
    <hr />
  </>
);

const BasicRow = ({ label, body }: {label: string, body: any}) => (
  <div style={{ display: 'flex', flexDirection: 'row' }}>
    <div style={{ width: COLUMN_WIDTH }}>
      <b>
        {label}
        :
        {' '}
      </b>
    </div>
    {body}
  </div>
);

const Basics = ({ data }: {data: any}) => (
  <>
    {Object.entries(data).map((entry) => <BasicRow key={entry[0]} label={entry[0]} body={entry[1]} />)}
  </>
);

const WorkRow = ({
  company, position, startDate, endDate, summary, highlights = [],
}: {company: string, position: string, startDate: string, endDate: string, summary?: string, highlights?: string[]}) => (
  <div>
    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '30px' }}>
      <BasicRow label={company} body={position} />
      <span style={{ marginLeft: COLUMN_WIDTH, marginBottom: '10px' }}>
        {startDate}
        {' '}
        -
        {' '}
        {endDate}
      </span>
      {summary && <span style={{ marginLeft: OFFSET }}>{summary}</span>}
      {highlights.length
        && (
        <ul>
          {highlights.map((entry) => <li style={{ marginLeft: OFFSET }} key={entry}>{entry}</li>)}
        </ul>
        )}
    </div>
  </div>
);
const WorkExperience = ({ data } : {data: any}) => (
  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '50px' }}>
    {data.map((entry) => <WorkRow key={entry.startDate + entry.endDate} {...entry} />)}
  </div>
);

const Skills = ({ data } : {data: any}) => (
  <ul>
    {data.map((entry) => (
      <li key={entry.name}>
        <b>{entry.name}</b>
        {Boolean(entry.level) && (
        <span style={{ marginLeft: '2px' }}>
          (
          {entry.level}
          )
        </span>
        )}
        {Boolean(entry.keywords && entry.keywords.length)
          && <span style={{ marginLeft: '10px' }}>{entry.keywords.join(', ')}</span>}
      </li>
    ))}
  </ul>
);

export default () => (
  <Layout>
    <div style={{ minWidth: MIN_WIDTH }}>
      <Section name="Basics">
        <Basics data={cv.basics} />
      </Section>
      <Section name="Work Experience">
        <WorkExperience data={cv.work} />
      </Section>
      <Section name="Skills">
        <Skills data={cv.skills} />
      </Section>
    </div>
  </Layout>
);
